import React from 'react'
import PropTypes from 'prop-types'
import Link from '../Link'
import IconMail from '../../images/blog/mail.inline.svg'
import HubspotForm from '../hubspot/HubspotForm'
import IconFacebook from '../../images/facebook.inline.svg'
import IconTwitter from '../../images/twitter.inline.svg'
import IconLinkedIn from '../../images/linkedin.inline.svg'
import IconYouTube from '../../images/youtube.inline.svg'
import IconInstagram from '../../images/instagram.inline.svg'
import useSeo from '../../hooks/useSeo'

function SubscribeHorizontalWidget({ className, knowledgeBase, blog }) {
  const { social } = useSeo()
  const twitterUrl = `https://twitter.com/${social.twitter.username}`
  const facebookUrl = social.facebook.url
  const youtubeUrl = social.youTube.url
  const linkedInUrl = social.linkedIn.url
  const instagramUrl = social.instagram.url

  return (
    <div
      className={`w-full h-full top-0 left-0 py-10 ${
        knowledgeBase ? 'knowledgeBase' : ''
      } ${className}`}
      style={{
        backgroundImage: knowledgeBase
          ? `linear-gradient(90deg, #03a0ca, #572e6e 100%)`
          : blog
          ? 'none'
          : `radial-gradient(at center center, #4b525e 0%, #3b485f 100%`,
      }}
      role="presentation"
    >
      {knowledgeBase && (
        <div className="container grid lg:grid-cols-12 text-white items-center">
          <div className="lg:col-span-5 text-xl font-semibold lg:text-left text-center mb-4 lg:mb-0">
            Want More Great Content Sent to Your Inbox?
          </div>
          <div className="lg:col-span-7 text-center lg:text-right">
            <HubspotForm
              standardFormID="c10f9bcb-e58c-495e-a685-b0a3c44c8c9b"
              cssClass="lw-hubspot-form-subscribe"
            />
          </div>
        </div>
      )}

      {blog && (
        <div className="container relative text-center">
          <div
            className="py-2 px-5 text-3xl font-semibold"
            style={{ color: '#007199' }}
          >
            Subscribe to Our Blog
          </div>
          <p className="text-base font-normal py-2 mx-5">
            Keep up to date with the latest Hosting news.
          </p>
          <div className="container py-5">
            <HubspotForm
              standardFormID="32ecff0a-1868-49a2-bade-c6707e6b9720"
              cssClass="lw-hubspot-form-subscribe subscribe-footer"
            />
          </div>
          <div>
            <ul className="mb-4 flex justify-center items-center">
              <li>
                <Link
                  aria-label="facebook"
                  className="inline-block px-2"
                  style={{ color: `#007199` }}
                  to={facebookUrl}
                >
                  <IconFacebook className="fill-current w-4" />
                </Link>
              </li>
              <li>
                <Link
                  aria-label="twitter"
                  className="inline-block px-2"
                  style={{ color: `#007199` }}
                  to={twitterUrl}
                >
                  <IconTwitter className="fill-current w-4" />
                </Link>
              </li>
              <li>
                <Link
                  aria-label="instagram"
                  className="inline-block px-2"
                  style={{ color: `#007199` }}
                  to={instagramUrl}
                >
                  <IconInstagram className="fill-current w-4" />
                </Link>
              </li>
              <li>
                <Link
                  aria-label="linkedin"
                  className="inline-block px-2"
                  style={{ color: `#007199` }}
                  to={linkedInUrl}
                >
                  <IconLinkedIn className="fill-current w-4" />
                </Link>
              </li>
              <li>
                <Link
                  aria-label="youtube"
                  className="inline-block px-2"
                  style={{ color: `#007199` }}
                  to={youtubeUrl}
                >
                  <IconYouTube className="fill-current w-4" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}

      {!knowledgeBase && !blog && (
        <div className="container relative text-center text-white">
          <div className="w-16 h-16 text-blue-400 mx-auto mt-5 bm-1">
            <IconMail />
          </div>
          <div className="py-1 px-5 text-3xl font-semibold">
            Want more news and updates like this straight to your inbox?
          </div>
          <p className="text-base font-normal mb-4 mx-5">
            Keep up to date with the latest Hosting news.
          </p>
          <div className="container py-5">
            <HubspotForm
              standardFormID="32ecff0a-1868-49a2-bade-c6707e6b9720"
              cssClass="lw-hubspot-form-subscribe subscribe-footer"
            />
          </div>
        </div>
      )}
    </div>
  )
}

SubscribeHorizontalWidget.propTypes = {
  className: PropTypes.string,
  knowledgeBase: PropTypes.bool,
  blog: PropTypes.bool,
}

export default SubscribeHorizontalWidget
