import React from 'react'
import { Link, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/Seo'
import StructuredData from '../components/structured-data'
import SearchForm from '../components/search/search-form'
import SubscribeHorizontalWidget from '../components/subscribe-widget/subscribe-horizontal-widget'
import LatestArticles from '../components/kb/latest-articles'

const KBTemplate = props => {
  const {
    data: {
      questions,
      gettingStarted,
      tutorial,
      series,
      commonFixes,
      support,
      products,
    },
  } = props

  const imgs = [
    {
      to: '/kb/category/getting-started/',
      heading: 'Getting Started',
      image: gettingStarted,
      alt: 'Getting Started Category Icon',
    },
    {
      to: '/kb/category/tutorials/',
      heading: 'Tutorials',
      image: tutorial,
      alt: 'Tutorials Category Icon',
    },
    {
      to: '/kb/category/series/',
      heading: 'Series',
      image: series,
      alt: 'Series Category Icon',
    },
    {
      to: '/kb/category/common-fixes/',
      heading: 'Common Fixes',
      image: commonFixes,
      alt: 'Common Fixes Category Icon',
    },
    {
      to: '/kb/category/technical-support/',
      heading: 'Technical Support',
      image: support,
      alt: 'Technical Support Category Icon',
    },
    {
      to: '/kb/category/products/',
      heading: 'Products',
      image: products,
      alt: 'Products Category Icon',
    },
  ]

  const post = {
    title: `Knowledge Base`,
    seo: {
      description: `Our Knowledge Base offers support from the Most Helpful Humans in Hosting™ with extensive web hosting resources and helpful articles. Find the answers you need today!`,
    },
  }

  return (
    <Layout>
      <Seo post={post} />
      <StructuredData pageType="webPage" post={post} />
      <div
        className="md:py-24 pt-8 mb-5 text-center"
        style={{
          backgroundImage: `linear-gradient(90deg, #03a0ca, #572e6e 100%)`,
        }}
      >
        <div
          role="doc-subtitle"
          className="py-1 px-5 text-lg text-white uppercase font-semibold leading-10"
        >
          GOT WEB HOSTING QUESTIONS? WE&apos;VE GOT THE ANSWERS.
        </div>
        <h1 className="text-white leading-10">Liquid Web Knowledge Base</h1>
        <div className="py-1 px-5 text-lg text-white font-semibold md:mb-10 leading-10">
          Browse through our comprehensive range of helpful Articles, Tutorials,
          and Resources:
        </div>
        <div className="container">
          <SearchForm kb={true} />
        </div>
      </div>
      <div className="container grid my-5 md:grid-cols-12 gap-y-20 md:gap-y-0 md:gap-x-10">
        <div className="col-span-4 pb-2">
          <h2 className="text-3xl font-semibold inline-block knowledgeBaseTextColor">
            Common Questions
            <span className="knowledgeBaseBorder mb-4 w-2/4" />
          </h2>
          <div className="font-semibold knowledgeBaseTextColor mb-5">
            Just getting started? Here are some common questions with
            step-by-step solutions.
          </div>
          {questions &&
            questions.nodes &&
            questions.nodes.map((question, index) => (
              <div key={index} className="mb-5">
                <Link
                  to={question.uri.replace('/blog', '')}
                  dangerouslySetInnerHTML={{ __html: question.title }}
                  className="font-semibold"
                />
                <div className="text-grey-darker my-2 text-sm md:text-base">
                  <div>
                    Reading Time: {question.readingTime}{' '}
                    {question.readingTime === '< 1' ? 'minute' : 'minutes'}
                  </div>
                  {question && question.excerpt && (
                    <div
                      className="search-excerpt text-grey-darker text-sm md:text-base"
                      dangerouslySetInnerHTML={{
                        __html: question.excerpt
                          .split(' ')
                          .slice(0, 20)
                          .join(' '),
                      }}
                    />
                  )}
                  <Link
                    aria-label="Read Article"
                    to={question.uri.replace('/blog', '')}
                  >
                    {' '}
                    [ Read Article ]
                  </Link>
                </div>
              </div>
            ))}
          <Link
            to="/kb/tag/common-questions/"
            className="btn btn-primary w-auto px-10 font-semibold hover:text-black focus:outline-none"
          >
            See More Featured Articles
          </Link>
        </div>
        <div className="col-span-8">
          <h2 className="text-3xl inline-block font-semibold knowledgeBaseTextColor">
            Browse Articles
            <span className="knowledgeBaseBorder mb-4 w-2/4" />
          </h2>
          <div className="font-semibold knowledgeBaseTextColor">
            Look through our popular article categories to find the answers you
            need.
          </div>
          <div className="container grid px-0 my-5 md:grid-cols-3 gap-x-10 md:gap-y-10">
            {imgs.map(({ to, heading, image, alt }) => {
              return (
                <Link
                  key={heading}
                  className="col-span-1 py-10 px-4 text-center hover:shadow-lg"
                  style={{ backgroundColor: '#f4f4f4' }}
                  to={to}
                  aria-label={heading}
                >
                  <GatsbyImage
                    className="inline-block"
                    image={image.childImageSharp.gatsbyImageData}
                    alt={alt}
                  />
                  <span className="block knowledgeBaseTextColor text-lg font-semibold">
                    {heading}
                  </span>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
      <LatestArticles />
      <SubscribeHorizontalWidget knowledgeBase={true} />
    </Layout>
  )
}

KBTemplate.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    questions: PropTypes.shape({
      childImageSharp: PropTypes.object,
    }),
    gettingStarted: PropTypes.shape({
      childImageSharp: PropTypes.object,
    }),
    tutorial: PropTypes.shape({
      childImageSharp: PropTypes.object,
    }),
    series: PropTypes.shape({
      childImageSharp: PropTypes.object,
    }),
    commonFixes: PropTypes.shape({
      childImageSharp: PropTypes.object,
    }),
    support: PropTypes.shape({
      childImageSharp: PropTypes.object,
    }),
    products: PropTypes.shape({
      childImageSharp: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query KBPage {
    questions: allWpKnowledgeBase(
      limit: 4
      filter: {
        knowledgeBaseTags: {
          nodes: { elemMatch: { slug: { eq: "common-questions" } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        excerpt
        uri
        title
        readingTime
      }
    }
    gettingStarted: file(relativePath: { eq: "kb/icon-getting-started.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 90
          placeholder: TRACED_SVG
          layout: FIXED
        )
      }
    }
    tutorial: file(relativePath: { eq: "kb/icon-tutorial.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 90
          placeholder: TRACED_SVG
          layout: FIXED
        )
      }
    }
    series: file(relativePath: { eq: "kb/icon-series.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 90
          placeholder: TRACED_SVG
          layout: FIXED
        )
      }
    }
    commonFixes: file(relativePath: { eq: "kb/icon-common-fixes.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 90
          placeholder: TRACED_SVG
          layout: FIXED
        )
      }
    }
    support: file(relativePath: { eq: "kb/icon-technical-support.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 90
          placeholder: TRACED_SVG
          layout: FIXED
        )
      }
    }
    products: file(relativePath: { eq: "kb/icon-products.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 90
          placeholder: TRACED_SVG
          layout: FIXED
        )
      }
    }
  }
`

export default KBTemplate
