import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

function LatestArticles({ className }) {
  const { articles } = useStaticQuery(graphql`
    {
      articles: allWpKnowledgeBaseCategory(
        sort: { fields: knowledgeBases___nodes___date, order: DESC }
        limit: 5
      ) {
        nodes {
          uri
          name
          knowledgeBases {
            nodes {
              uri
              title
            }
          }
        }
      }
    }
  `)

  return (
    <div
      className={`py-10 ${className}`}
      style={{ backgroundColor: '#f7f7f7' }}
    >
      <div className="text-center mb-6">
        <h2 className="text-3xl inline-block font-semibold knowledgeBaseTextColor">
          Latest Articles
          <span className="knowledgeBaseBorder mx-auto w-2/4" />
        </h2>
      </div>
      <div className="container grid md:px-0 my-5 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-5 md:gap-y-5">
        {articles &&
          articles.nodes &&
          articles.nodes.map(({ uri, name, knowledgeBases }) => (
            <div className="col-span-1 bg-white text-center" key={uri}>
              <div
                className="uppercase font-bold p-5 mb-8"
                style={{ backgroundColor: '#1264a3' }}
              >
                <Link
                  to={uri.replace('/blog', '/kb')}
                  dangerouslySetInnerHTML={{
                    __html: name,
                  }}
                  className="text-white hover:text-white"
                />
              </div>
              {knowledgeBases &&
                knowledgeBases.nodes &&
                knowledgeBases.nodes[0] && (
                  <div className="mb-5">
                    <p className="p-2 mb-3" style={{ minHeight: '92px' }}>
                      {knowledgeBases.nodes[0].title}
                    </p>
                    <Link
                      to={knowledgeBases.nodes[0].uri.replace('/blog', '')}
                      className="underline"
                    >
                      Read Article
                    </Link>
                  </div>
                )}
            </div>
          ))}
      </div>
    </div>
  )
}

LatestArticles.propTypes = {
  className: PropTypes.string,
}

export default LatestArticles
